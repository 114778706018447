<template>
    <div class="blank-layout">
        <div class="blank-layout__content">
            <div class="container">
                <div class="blank-layout__language-selector-container">
                    <languages variant="link" class="blank-layout__language-selector" />
                </div>
                <router-view />
                <p class="blank-layout__copy">
                    &copy; {{ new Date().getFullYear() }} Tendencys. {{ $t('All rights reserved') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Languages from "@/components/Languages";

export default {
    name: 'blank-layout',
    components: { Languages },
};
</script>

<style lang="scss">
.blank-layout {
    background-color: $general-background;
    min-height: 100vh;
    width: 100%;

    &__content {
        margin: 0 auto;
        max-width: 852px;
        padding: 75px 0;

        @media(max-width: 768px) {
            padding-top: 20px;
        }
    }

    &__language-selector-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }

    &__copy {
        color: $ecart-pay-secondary-500;
        margin-bottom: 0;
        margin-top: 40px;
        text-align: center;
    }
}
</style>
